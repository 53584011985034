import qs from 'qs'
import apiClient from 'services/axios'

const uri = '/admin/blocked-vehicle'
const uriVehicle = '/admin/vehicle'

export async function index(params) {
  return apiClient
    .get(uri, { params })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function indexAvailableUnit(params) {
  return apiClient
    .get(`${uriVehicle}/available-unit`, { params })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function store({ payload, id }) {
  if (id) {
    return apiClient
      .put(`${uri}/${id}`, qs.stringify(payload))
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  }
  return apiClient
    .post(uri, qs.stringify(payload))
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function detail(id) {
  return apiClient
    .get(`${uri}/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteMethod(id) {
  return apiClient
    .delete(`${uri}/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
