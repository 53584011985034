import React, { lazy, Suspense } from 'react'
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { Spinner } from 'reactstrap'

import Layout from 'layouts'

const routes = [
  // role management
  {
    path: '/role-management',
    Component: lazy(() => import('pages/role-management')),
    exact: true,
  },
  {
    path: '/role-management/create',
    Component: lazy(() => import('pages/role-management/store')),
    exact: true,
  },
  {
    path: '/role-management/update/:roleId',
    Component: lazy(() => import('pages/role-management/store')),
    exact: true,
  },
  // partner management
  {
    path: '/partner',
    Component: lazy(() => import('pages/partners')),
    exact: true,
  },
  // user management
  {
    path: '/user-management',
    Component: lazy(() => import('pages/users')),
    exact: true,
  },
  {
    path: '/user-management/create',
    Component: lazy(() => import('pages/users/store')),
    exact: true,
  },
  {
    path: '/user-management/update/:userId',
    Component: lazy(() => import('pages/users/store')),
    exact: true,
  },
  {
    path: '/user-management/detail/:userId',
    Component: lazy(() => import('pages/users/detail')),
    exact: true,
  },
  // admin management
  {
    path: '/admin',
    Component: lazy(() => import('pages/users-admin')),
    exact: true,
  },
  {
    path: '/admin/create',
    Component: lazy(() => import('pages/users-admin/store')),
    exact: true,
  },
  {
    path: '/admin/update/:userId',
    Component: lazy(() => import('pages/users-admin/store')),
    exact: true,
  },
  // vehicle management
  {
    path: '/vehicle-management/brand',
    Component: lazy(() => import('pages/vehicle-management/brand')),
    exact: true,
  },
  {
    path: '/vehicle-management/brand-type',
    Component: lazy(() => import('pages/vehicle-management/brand-type')),
    exact: true,
  },
  {
    path: '/vehicle-management/vehicle',
    Component: lazy(() => import('pages/vehicle-management/vehicle')),
    exact: true,
  },
  {
    path: '/vehicle-management/vehicle/create',
    Component: lazy(() => import('pages/vehicle-management/vehicle/store')),
    exact: true,
  },
  {
    path: '/vehicle-management/vehicle/update/:vehicleId',
    Component: lazy(() => import('pages/vehicle-management/vehicle/store')),
    exact: true,
  },
  {
    path: '/vehicle-management/freeze',
    Component: lazy(() => import('pages/vehicle-management/vehicle-freeze')),
    exact: true,
  },
  {
    path: '/vehicle-management/freeze/create',
    Component: lazy(() => import('pages/vehicle-management/vehicle-freeze/store')),
    exact: true,
  },
  // reservation management
  {
    path: '/reservations',
    Component: lazy(() => import('pages/reservations')),
    exact: true,
  },
  {
    path: '/reservations/detail/:reservationId',
    Component: lazy(() => import('pages/reservations/detail')),
    exact: true,
  },
  // payment management
  {
    path: '/payment-management',
    Component: lazy(() => import('pages/payment')),
    exact: true,
  },
  {
    path: '/payment-management/create',
    Component: lazy(() => import('pages/payment/store')),
    exact: true,
  },
  // banner management
  {
    path: '/banner',
    Component: lazy(() => import('pages/banner')),
    exact: true,
  },
  // comment management
  {
    path: '/comment',
    Component: lazy(() => import('pages/comment')),
    exact: true,
  },
  // article management
  {
    path: '/article-management/article',
    Component: lazy(() => import('pages/article-management/article')),
    exact: true,
  },
  {
    path: '/article-management/article/create',
    Component: lazy(() => import('pages/article-management/article/store')),
    exact: true,
  },
  {
    path: '/article-management/article/update/:articleId',
    Component: lazy(() => import('pages/article-management/article/store')),
    exact: true,
  },
  // article category management
  {
    path: '/article-management/category',
    Component: lazy(() => import('pages/article-management/category')),
    exact: true,
  },
  // Dashboards
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/dashboard')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/reset-password/:token',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  // Partner
  {
    path: '/dashboard-partner',
    Component: lazy(() => import('pages/partner-menu/dashboard')),
    exact: true,
  },
  {
    path: '/dashboard-partner/vehicle/:id',
    Component: lazy(() => import('pages/partner-menu/vehicles')),
    exact: true,
  },
  // My Profile
  {
    path: '/my-profile/:userId',
    Component: lazy(() => import('pages/my-profile')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  user,
})

const Router = ({ history, routerAnimation, user }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <HashRouter>
          <Route
            render={state => {
              const { location } = state
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={location.pathname}
                    appear
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    <Switch location={location}>
                      <Route
                        exact
                        path="/"
                        render={() => (
                          <Redirect
                            to={
                              user?.role !== 'Admin Partner' ? '/dashboard' : '/dashboard-partner'
                            }
                          />
                        )}
                      />
                      {routes.map(({ path, Component, exact }) => (
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense
                                  fallback={
                                    <div className="w-100 h-100 d-flex justify-content-center">
                                      <Spinner color="info" />
                                    </div>
                                  }
                                >
                                  <Component />
                                </Suspense>
                              </div>
                            )
                          }}
                        />
                      ))}
                      <Redirect to="/auth/404" />
                    </Switch>
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          />
        </HashRouter>
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
