const actions = {
  SET_STATE: 'partner/SET_STATE',
  INDEX: 'partner/INDEX',
  CREATE: 'partner/CREATE',
  DETAIL: 'partner/DETAIL',
  UPDATE: 'partner/UPDATE',
  DELETE: 'partner/DELETE',
}

export default actions
