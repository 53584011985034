import { all } from 'redux-saga/effects'
import user from './user/sagas'
import auth from './auth/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import role from './roles/sagas'
import userManagement from './user-management/sagas'
import brand from './brand/sagas'
import brandType from './brand-type/sagas'
import vehicle from './vehicle/sagas'
import blockedVehicle from './blocked-vehicles/sagas'
import summary from './summary/sagas'
import partner from './partners/sagas'
import reservation from './reservation/sagas'
import payment from './payment/sagas'
import banner from './banner/sagas'
import article from './article/sagas'
import articleCategory from './article-category/sagas'
import notes from './notes/sagas'
import bank from './bank/sagas'
import comment from './comment/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    auth(),
    menu(),
    settings(),
    role(),
    userManagement(),
    brand(),
    brandType(),
    vehicle(),
    summary(),
    partner(),
    reservation(),
    payment(),
    banner(),
    article(),
    articleCategory(),
    notes(),
    bank(),
    comment(),
    blockedVehicle(),
  ])
}
