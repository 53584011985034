import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import auth from './auth/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import role from './roles/reducers'
import userManagement from './user-management/reducers'
import brand from './brand/reducers'
import brandType from './brand-type/reducers'
import vehicle from './vehicle/reducers'
import blockedVehicle from './blocked-vehicles/reducers'
import summary from './summary/reducers'
import partner from './partners/reducers'
import reservation from './reservation/reducers'
import payment from './payment/reducers'
import banner from './banner/reducers'
import article from './article/reducers'
import articleCategory from './article-category/reducers'
import notes from './notes/reducers'
import bank from './bank/reducers'
import comment from './comment/reducers'

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    role,
    userManagement,
    brand,
    brandType,
    vehicle,
    summary,
    partner,
    reservation,
    payment,
    banner,
    article,
    articleCategory,
    notes,
    bank,
    comment,
    auth,
    blockedVehicle,
  })
