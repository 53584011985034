import moment from 'moment'
import 'moment/locale/id'
// Other Helper
import constant from './constant'

export const formatDate = (value, format = 'MMMM DD, YYYY hh:mm A') => {
  if (!value) return 'Empty'
  let newDate
  switch (format) {
    case 'moment':
      return value === 'today' ? moment() : moment(value)
    default:
      newDate = value === 'today' ? moment().format(format) : moment(value).format(format)
      newDate = newDate.toLocaleLowerCase().includes('invalid') ? '-' : newDate
      return newDate
  }
}

export const moneyFormatter = (num = '', prefix = 'IDR', ext = null) => {
  const newNumbers = String(num).replace(/\.00$/, '')
  const newExt = ext !== null ? ext : ''
  const result = newNumbers.replace(/\D*/g, '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') || ''
  return `${prefix} ${result}${newExt}`
}

export const getPaymentType = value => {
  // 0: down payment, 1: full payment, 8: extra payment, 9: refund deposit
  const v = value || 'e'
  const x = {
    0: { name: 'Down Payment', color: 'volcano' },
    1: { name: 'Full Payment', color: 'green' },
    7: { name: 'Extra Payment', color: 'green' },
    8: { name: 'Refund Extra Cost', color: 'red' },
    9: { name: 'Refund Deposit', color: 'red' },
    e: { name: '-', color: 'red' },
  }
  return x[v]
}

export const getPaymentStatus = value => {
  // 0: waiting for confimation, 1: success, 9: failed
  const v = value || 'e'
  const x = {
    0: { name: 'Waiting confimation', color: 'warning' },
    1: { name: 'Accepted', color: 'success' },
    9: { name: 'Rejected', color: 'red' },
    e: { name: '-', color: 'black' },
  }
  return x[v]
}

export { constant }

export const objectNotEmpty = data => (data ? Object.keys(data).length > 0 : false)
