const actions = {
  SET_STATE: 'role/SET_STATE',
  // LOGIN: 'user/LOGIN',
  // REGISTER: 'user/REGISTER',
  // LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  // LOGOUT: 'user/LOGOUT',
  INDEX: 'role/INDEX'
}

export default actions
