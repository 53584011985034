const actions = {
  SET_STATE: 'blocked-vehicle/SET_STATE',
  INDEX: 'blocked-vehicle/INDEX',
  INDEX_AU: 'blocked-vehicle/INDEX_AU',
  CREATE: 'blocked-vehicle/CREATE',
  DETAIL: 'blocked-vehicle/DETAIL',
  UPDATE: 'blocked-vehicle/UPDATE',
  DELETE: 'blocked-vehicle/DELETE_VEHICLE',
}

export default actions
