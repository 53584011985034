const actions = {
  SET_STATE: 'user-management/SET_STATE',
  INDEX: 'user-management/INDEX',
  EXPORT: 'user-management/EXPORT',
  CREATE: 'user-management/CREATE',
  DETAIL: 'user-management/DETAIL',
  UPDATE: 'user-management/UPDATE',
  DELETE: 'user-management/DELETE_USER',
}

export default actions
