import apiClient from 'services/axios'
import qs from 'qs'
import { constant } from 'services/helper';

const { URL_PORTAL } = constant.processEnv;
const forgot = '/auth/forgot/password'
const reset = '/auth/reset/password'

export async function forgotPassword(payload) {
  const newPayload = payload;
  newPayload.reset_url = `${URL_PORTAL}/#/auth/reset-password`

  return apiClient
    .post(forgot, qs.stringify(newPayload))
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function resetPassword(payload) {
  return apiClient
    .post(reset, qs.stringify(payload))
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
