const {
  REACT_APP_RIPLE_API,
  REACT_APP_URL_DATASTORE,
  REACT_APP_SUPERADMIN_ROLE,
  REACT_APP_ADMIN_ROLE,
  REACT_APP_PARTNER_ROLE,
  REACT_APP_USER_ROLE,
  REACT_APP_BANNER_PARENT_ID,
  REACT_APP_URL_PORTAL,
  REACT_APP_URL_APP,
} = process.env

const processEnv = {
  URL_API: REACT_APP_RIPLE_API,
  URL_DATASTORE: REACT_APP_URL_DATASTORE,
  URL_PORTAL: REACT_APP_URL_PORTAL,
  URL_APP: REACT_APP_URL_APP,
  role: {
    superadmin: REACT_APP_SUPERADMIN_ROLE,
    admin: REACT_APP_ADMIN_ROLE,
    partner: REACT_APP_PARTNER_ROLE,
    user: REACT_APP_USER_ROLE,
  },
  banner: {
    default_parent_id: REACT_APP_BANNER_PARENT_ID,
  },
}

export default { processEnv }
