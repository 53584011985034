import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
import * as notes from 'services/api/notes'
import actions from './actions'

export function* INDEX({ payload, customKey }) {
  const key = customKey || 'data'
  yield put({
    type: 'notes/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(notes.index, payload)
  if (success) {
    const { values } = success
    yield put({
      type: 'notes/SET_STATE',
      payload: {
        loading: false,
        success: true,
        [key]: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'notes/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
    })
  }
}

// export function* DELETE({ id }) {
//   yield put({
//     type: 'notes/SET_STATE',
//     payload: {
//       loading: true,
//       success: false,
//     },
//   })

//   const success = yield call(notes.deletenotes, id)
//   if (success) {
//     yield put({
//       type: 'notes/SET_STATE',
//       payload: {
//         loading: false,
//         success: true,
//       },
//     })
//     message.success('Success delete notes!')
//   }

//   if (!success) {
//     yield put({
//       type: 'notes/SET_STATE',
//       payload: {
//         loading: false,
//         success: false,
//       },
//     })
//   }
// }

export function* STORE({ payload, id }) {
  yield put({
    type: 'notes/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(notes.store, { payload, id })
  if (success) {
    yield put({
      type: 'notes/SET_STATE',
      payload: {
        loading: false,
        success: true,
      },
    })
    message.success(id ? 'Success update notes!' : 'Success create new notes!')
  }

  if (!success) {
    yield put({
      type: 'notes/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export function* DETAIL({ id }) {
  yield put({
    type: 'notes/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(notes.detail, id)
  if (success) {
    const { values } = success
    yield put({
      type: 'notes/SET_STATE',
      payload: {
        loading: false,
        success: true,
        detail: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'notes/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INDEX, INDEX),
    takeEvery(actions.CREATE, STORE),
    takeEvery(actions.UPDATE, STORE),
    takeEvery(actions.DETAIL, DETAIL),
    // takeEvery(actions.DELETE, DELETE),
  ])
}
