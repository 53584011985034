import apiClient from 'services/axios'
import qs from 'qs'

const uri = '/admin/role';
export async function index(params) {
  return apiClient
    .get(uri, { params })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function create(data) {
  return apiClient
    .post(uri, qs.stringify(data))
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
