import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as summary from 'services/api/summary'
import actions from './actions'

export function* INDEX({ payload }) {
  yield put({
    type: 'summary/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(summary.summary, payload)
  if (success) {
    const { values } = success
    yield put({
      type: 'summary/SET_STATE',
      payload: {
        loading: false,
        success: true,
        data: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'summary/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
    })
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.INDEX, INDEX)])
}
