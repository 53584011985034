import apiClient from 'services/axios'
import store from 'store'
import qs from 'qs'

export async function login(email, password) {
  return apiClient
    .post(
      '/auth/login',
      qs.stringify({
        email,
        password,
      }),
    )
    .then(response => {
      if (response) {
        const { token } = response.data.values
        if (token) {
          store.set('accessToken', token)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/auth/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/profile')
    .then(response => {
      if (response) {
        // const { accessToken } = response.data
        // if (accessToken) {
        //   store.set('accessToken', accessToken)
        // }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}
