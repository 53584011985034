import apiClient from 'services/axios'
import qs from 'qs'

const uri = '/admin/brand'
export async function index(params) {
  return apiClient
    .get(uri, { params })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function store({ payload, id }) {
  if (id) {
    return apiClient
      .put(`${uri}/${id}`, qs.stringify(payload))
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  }
  return apiClient
    .post(uri, qs.stringify(payload))
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function detail(id) {
  return apiClient
    .get(`${uri}/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteUser(id) {
  return apiClient
    .delete(`${uri}/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
