import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
import * as comment from 'services/api/comment'
import actions from './actions'

export function* INDEX({ payload }) {
  yield put({
    type: 'comment/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(comment.index, payload)
  if (success) {
    const { values } = success
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: true,
        data: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
    })
  }
}

export function* DELETE({ id }) {
  yield put({
    type: 'comment/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(comment.deleteComment, id)
  if (success) {
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: true,
      },
    })
    message.success('Success delete comment type!')
  }

  if (!success) {
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export function* STORE({ payload, id }) {
  yield put({
    type: 'comment/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(comment.store, { payload, id })
  if (success) {
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: true,
      },
    })
    message.success(id ? 'Success update comment!' : 'Success create new comment!')
  }

  if (!success) {
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export function* DETAIL({ id }) {
  yield put({
    type: 'comment/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(comment.detail, id)
  if (success) {
    const { values } = success
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: true,
        detail: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'comment/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INDEX, INDEX),
    takeEvery(actions.CREATE, STORE),
    takeEvery(actions.UPDATE, STORE),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.DELETE, DELETE),
  ])
}
