import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification, message } from 'antd'
import * as vehicleApi from 'services/api/vehicle'
import actions from './actions'

export function* INDEX({ payload, isPartner }) {
  yield put({
    type: 'vehicle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(vehicleApi.index, payload, isPartner)
  if (success) {
    const { values } = success
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: true,
        data: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
    })
  }
}

export function* INDEX_AU({ payload }) {
  yield put({
    type: 'vehicle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(vehicleApi.indexAvailableUnit, payload)
  if (success) {
    const { values } = success
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: true,
        data: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
    })
  }
}

export function* EXPORT({ payload, isPartner }) {
  yield put({
    type: 'vehicle/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(vehicleApi.index, payload, isPartner)
  if (success) {
    const { values } = success
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: true,
        dataExport: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
    })
  }
}

export function* DELETE({ id }) {
  yield put({
    type: 'vehicle/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(vehicleApi.deleteUser, id)
  if (success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: true,
      },
    })
    message.success('Success delete vehicle!')
  }

  if (!success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export function* STORE({ payload, id }) {
  yield put({
    type: 'vehicle/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(vehicleApi.store, { payload, id })
  if (success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: true,
      },
    })
    message.success(id ? 'Success update vehicle!' : 'Success create new vehicle!')
  }

  if (!success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export function* DETAIL({ id, isPartner }) {
  yield put({
    type: 'vehicle/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(vehicleApi.detail, id, isPartner)
  if (success) {
    const { values } = success
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: true,
        detail: values,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'vehicle/SET_STATE',
      payload: {
        loading: false,
        success: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INDEX, INDEX),
    takeEvery(actions.INDEX_AU, INDEX_AU),
    takeEvery(actions.EXPORT, EXPORT),
    takeEvery(actions.CREATE, STORE),
    takeEvery(actions.UPDATE, STORE),
    takeEvery(actions.DETAIL, DETAIL),
    takeEvery(actions.DELETE, DELETE),
  ])
}
