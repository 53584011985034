import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import { constant } from '../helper'

const apiClient = axios.create({
  baseURL: constant.processEnv.URL_API,
  timeout: 90000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  const { data } = response
  if (data) {
    let enm = data.values ? `${Object.values(data.values)[0]}` : null
    if (data?.values?.errors) {
      const dataErr = data.values.errors
      Object.keys(dataErr).forEach((e, i) => {
        if (i === 0) {
          // eslint-disable-next-line prefer-destructuring
          enm = dataErr[e][i]
        }
      })
    }
    notification.warning({
      message: enm || data?.message || 'Failed to fetch!',
    })
  }
})

export default apiClient
