import { all, takeEvery, put, call } from 'redux-saga/effects'
import * as auth from 'services/api/auth'
import actions from './actions'

export function* FORGOT_PASSWORD({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(auth.forgotPassword, payload)
  if (success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
        success: true,
        data: success.values
      },
    })
  }

  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
        success: false,
        data: success.values
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: 'auth/SET_STATE',
    payload: {
      loading: true,
      success: false,
    },
  })

  const success = yield call(auth.resetPassword, payload)
  if (success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
        success: true,
        data: success.values
      },
    })
  }

  if (!success) {
    yield put({
      type: 'auth/SET_STATE',
      payload: {
        loading: false,
        success: false,
        data: success.values
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FORGOT_PASSWORD, FORGOT_PASSWORD),
    takeEvery(actions.RESET_PASSWORD, RESET_PASSWORD),
  ])
}
