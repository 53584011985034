const actions = {
  SET_STATE: 'banner/SET_STATE',
  INDEX: 'banner/INDEX',
  CREATE: 'banner/CREATE',
  DETAIL: 'banner/DETAIL',
  UPDATE: 'banner/UPDATE',
  DELETE: 'banner/DELETE',
}

export default actions
