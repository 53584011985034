const actions = {
  SET_STATE: 'brand-type/SET_STATE',
  INDEX: 'brand-type/INDEX',
  INDEX_AVAILABLE: 'brand-type/INDEX_AVAILABLE',
  CREATE: 'brand-type/CREATE',
  DETAIL: 'brand-type/DETAIL',
  UPDATE: 'brand-type/UPDATE',
  DELETE: 'brand-type/DELETE',
}

export default actions
