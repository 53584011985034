import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as roles from 'services/api/roles'
// import store from 'store'
import actions from './actions'


export function* INDEX({ payload }) {
  yield put({
    type: 'role/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const success = yield call(roles.index, payload)
  if (success) {
    const { values } = success;
    yield put({
      type: 'role/SET_STATE',
      payload: {
        loading: false,
        data: values
      },
    })
  }

  if (!success) {
    yield put({
      type: 'role/SET_STATE',
      payload: {
        loading: false,
      },
    })
    notification.error({
      message: 'Failed to fetch data!',
      // description: 'You have successfully logged in!',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INDEX, INDEX),
  ])
}
