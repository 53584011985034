const actions = {
  SET_STATE: 'brand/SET_STATE',
  INDEX: 'brand/INDEX',
  CREATE: 'brand/CREATE',
  DETAIL: 'brand/DETAIL',
  UPDATE: 'brand/UPDATE',
  DELETE: 'brand/DELETE_BRAND',
}

export default actions
