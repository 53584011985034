const actions = {
  SET_STATE: 'vehicle/SET_STATE',
  INDEX: 'vehicle/INDEX',
  INDEX_AU: 'vehicle/INDEX_AU',
  EXPORT: 'vehicle/EXPORT',
  CREATE: 'vehicle/CREATE',
  DETAIL: 'vehicle/DETAIL',
  UPDATE: 'vehicle/UPDATE',
  DELETE: 'vehicle/DELETE_VEHICLE',
}

export default actions
