const actions = {
  SET_STATE: 'bank/SET_STATE',
  INDEX: 'bank/INDEX',
  CREATE: 'bank/CREATE',
  DETAIL: 'bank/DETAIL',
  UPDATE: 'bank/UPDATE',
  DELETE: 'bank/DELETE',
}

export default actions
