import apiClient from 'services/axios'

const uri = '/admin/comments'
const uriLog = '/admin/comments-log'
export async function index(params) {
  return apiClient
    .get(uri, { params })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function store({ payload, id }) {
  if (id) {
    return apiClient
      .put(`${uri}/${id}`, payload)
      .then(response => {
        if (response) {
          return response.data
        }
        return false
      })
      .catch(err => console.log(err))
  }
  return apiClient
    .post(uri, payload)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function detail(id) {
  return apiClient
    .get(`${uri}/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function deleteComment(id) {
  return apiClient
    .delete(`${uriLog}/${id}`)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
