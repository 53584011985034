const actions = {
  SET_STATE: 'reservation/SET_STATE',
  INDEX: 'reservation/INDEX',
  EXPORT: 'reservation/EXPORT',
  CREATE: 'reservation/CREATE',
  DETAIL: 'reservation/DETAIL',
  UPDATE: 'reservation/UPDATE',
  DELETE: 'reservation/DELETE',
  PAYMENT_CONFIRMATION: 'reservation/PAYMENT_CONFIRMATION',
  CHANGE_BR_VE: 'reservation/CHANGE_BR_VE',
}

export default actions
