const actions = {
  SET_STATE: 'payment/SET_STATE',
  INDEX: 'payment/INDEX',
  EXPORT: 'payment/EXPORT',
  CREATE: 'payment/CREATE',
  DETAIL: 'payment/DETAIL',
  UPDATE: 'payment/UPDATE',
  DELETE: 'payment/DELETE',
  PAYMENT_CONFIRMATION: 'payment/PAYMENT_CONFIRMATION',
}

export default actions
