export default async function getMenuData() {
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard-partner',
      roles: ['Admin Partner'],
    },
    {
      title: 'Partner',
      key: 'partner-management',
      icon: 'fe fe-briefcase',
      url: '/partner',
      roles: ['Superadmin', 'Admin'],
    },
    {
      category: true,
      title: 'Users',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Admin',
      key: 'admin-management',
      icon: 'fe fe-user-check',
      url: '/admin',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Users',
      key: 'user-management',
      icon: 'fe fe-users',
      url: '/user-management',
      roles: ['Superadmin', 'Admin'],
    },
    {
      category: true,
      title: 'Vehicle',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Brand',
      key: 'vm-brand',
      icon: 'fe fe-circle',
      url: '/vehicle-management/brand',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Tipe',
      key: 'vm-brand-type',
      icon: 'fe fe-target',
      url: '/vehicle-management/brand-type',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Unit',
      key: 'vm-vehicles',
      icon: 'fe fe-wind',
      url: '/vehicle-management/vehicle',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Freeze Unit',
      key: 'vm-vehicles-freeze',
      icon: 'fe fe-wind',
      url: '/vehicle-management/freeze',
      roles: ['Superadmin', 'Admin'],
    },
    {
      category: true,
      title: 'Booking',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Bookings',
      key: 'booking-management',
      icon: 'fe fe-calendar',
      url: '/reservations',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Payments',
      key: 'payment-management',
      icon: 'fe fe-dollar-sign',
      url: '/payment-management',
      roles: ['Superadmin', 'Admin'],
    },
    {
      category: true,
      title: 'Banner',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'App Banner',
      key: 'banner-management',
      icon: 'fe fe-image',
      url: '/banner',
      roles: ['Superadmin', 'Admin'],
    },
    {
      category: true,
      title: 'Article',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Kategori',
      key: 'category',
      icon: 'fe fe-feather',
      url: '/article-management/category',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Artikel',
      key: 'article',
      icon: 'fe fe-feather',
      url: '/article-management/article',
      roles: ['Superadmin', 'Admin'],
    },
    {
      category: true,
      title: 'Comment',
      roles: ['Superadmin', 'Admin'],
    },
    {
      title: 'Comment',
      key: 'comment',
      icon: 'fe fe-server',
      url: '/comment',
      roles: ['Superadmin', 'Admin'],
    },
  ]
}
