const actions = {
  SET_STATE: 'comment/SET_STATE',
  INDEX: 'comment/INDEX',
  CREATE: 'comment/CREATE',
  DETAIL: 'comment/DETAIL',
  UPDATE: 'comment/UPDATE',
  DELETE: 'comment/DELETE',
}

export default actions
